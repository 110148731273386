<template>
  <div class="blog-wrapper">
    <div class="header font">
      <div class="pad-cover" :style="`background-image: url(${post.image})`">
        <div class="header-content">
          <div class="title container">
            <h1>{{ post.title }}</h1>
          </div>
        </div>
      </div>
      <div class="header-bottom">
        <div class="container">
          <div class="blog-info">
            {{ post.time }}·{{ post.tag }}
          </div>
        </div>
      </div>
    </div>
    <div class="blog-body">
      <div class="container">
        <div class="blog-content font">
          <div class="block-item">
            <div class="video-wrapper">
              <iframe style="position:absolute;top:0;left:0;width:100%;height:100%;" src="https://www.youtube.com/embed/DLnjEWWZycs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <p> [台北市內湖社福中心]特地錄製影片給予GC贈物網鼓勵與肯定。</p>
          <p>立即申請成為贈物網公益會員：<a href="https://www.give-circle.com/story/13221" target="_blank">https://www.give-circle.com/story/13221</a></p>
        </div>
      </div>
    </div>
    <div class="blog-footer">
      <div class="container">
        <div class="footer-nav">
          <div class="btn-previous footer-btn">
            <div>上一篇</div>
            <a v-if="prev" class="title" :href="prev.link">{{ prev.title }}</a>
          </div>
          <div class="btn-next footer-btn">
            <div>下一篇</div>
            <a v-if="next" class="title" :href="next.link">{{ next.title }}</a>
          </div>
        </div>
        <div class="footer-toolbar">
          <a target="_self" href="/" class="back-link">
            <span class="left-open-big"></span>
            &nbsp;返回網站
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    prev: {
      type: Object,
      default: null
    },
    next: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="less">
.font {
  font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
}

.center {
  text-align: center;
}

.blog-wrapper {
  .container {
    position: relative;
    max-width: 816px;
    margin: 0 auto;
    padding: 0;
    padding: 0 15px;

    @media screen and (min-width: 728px) and (max-width: 879px) {
      max-width: 640px;
    }
    @media screen and (min-width: 590px) and (max-width: 727px) {
      max-width: 556px;
    }
    @media screen and (min-width: 490px) and (max-width: 589px) {
      max-width: 456px;
    }
    @media screen and (min-width: 370px) and (max-width: 429px) {
      max-width: 336px;
    }
    @media screen and (max-width: 349px) {
      max-width: 296px;
    }
  }

  .header {
    .pad-cover {
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      background-position: 50% 50%;
      color: #fff;
      height: 340px;
      display: table;
      width: 100%;

      // @media screen and (max-width: 727px) {
      //   height: 220px;
      // }

      .header-content {
        padding: 40px 0 60px;
        display: table-cell;
        vertical-align: bottom;
      }

      .title {
        font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
        text-shadow: 0 1px 12px rgb(0 0 0 / 35%);
      }
    }

    .header-bottom {
      .blog-info {
        padding: 12px 0;
        border-bottom: 1px solid #ddd;
        color: #a9aeb2;
        font-size: 14px;
      }
    }
  }
}

.blog-body {
  padding: 30px 0;
  .blog-content {
    color: #444;
    line-height: 1.7;
    word-wrap: break-word;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;

    .video-wrapper {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;
    }

    p {
      margin: 0;
      padding: 0;
      border: 0;
    }

    .block-item {
      margin-bottom: 30px;
    }

    .img-item {
      max-width: 100%;
    }

    .separator {
      height: 2px;
      background: #ddd;
      width: 240px;
      margin: 0 auto;
      border-radius: 2px;
    }
  }
}
.blog-footer {
  padding-top: 30px;
  padding-bottom: 200px;
  background: #fff;
  color: #999;

  .footer-nav {
    padding: 22px 0;
    font-size: 14px;
    border-top: 1px solid #ddd;
    color: #999;
  }

  .btn-previous {
    padding-right: 10px;
    box-sizing: border-box;
    color: #a9aeb2;
  }
  .footer-btn {
    display: inline-block;
    zoom: 1;
    width: 50%;
    vertical-align: top;
  }

  .btn-next {
    padding-left: 10px;
    box-sizing: border-box;
    color: #a9aeb2;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    color: #000;
    margin-top: 5px;
    line-height: 1.25;
    font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
    cursor: pointer;
    text-decoration: none;
  }

  .footer-toolbar {
    font-size: 14px;
    padding-top: 15px;
    border-top: 1px solid #ddd;

    .back-link {
      display: inline-block;
      margin: 0 30px 10px 0;
      line-height: 20px;
      color: #a9aeb2;
      text-decoration: none;

      .left-open-big {
        &::before {
          content: "<";
          font-family: entypo;
          font-style: normal;
          font-weight: 400;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
        }
      }
    }
  }
}
</style>