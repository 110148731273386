<template>
  <div class="modal-container">
    <div class="modal">
      <div class="wrapper font">
        <div class="heading">
          <span class="all">所有文章</span>
          <div class="close-btn" @click="$emit('close')">x</div>
        </div>
        <ul class="posts">
          <li v-for="(item, index) in posts" :key="index" class="post">
            <span :style="`background-image: url(${item.image})`" class="avatar"/>
            <div class="pad-info">
              <a class="title font" :href="item.link" @click="$emit('close')">
                {{ item.title }}
              </a>
              <span class="info">
                <span class="date">{{ item.time }}</span>
                <span class="dot">·</span>
                <span class="tag">{{ item.tag }}</span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posts: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
<style lang="less" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 3011;
}
.modal {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  text-align: left;
  background: #fff;
  .wrapper {
    width: 80%;
    max-width: 960px;
    margin: 0 auto;
  }
  .heading {
    height: 117px;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    padding: 50px 0 30px;
    text-transform: uppercase;
    word-break: break-word;
    width: inherit;
    max-width: 960px;
    position: fixed;
    background-color: #fff;
    z-index: 10;
    border-bottom: 1px solid #ddd;

    .all {
      margin: 5px 0;
      display: inline-block;
    }

    .close-btn {
      position: absolute;
      top: 60px;
      right: 0;
      display: inline-block;
      margin: -10px -2px 0 0;
      color: #888;
      cursor: pointer;
      font-size: 50px;
      font-weight: 300;
      font-family: open_sans;
      line-height: .5em;
    }
  }
}

.posts {
  padding: 117px 0 0;
  list-style: none;

  .post {
    line-height: 1.5em;
    border-bottom: 1px solid #ddd;
    padding: 25px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
    align-items: flex-start;

    .avatar {
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      -webkit-flex: auto 0 0;
      flex: auto 0 0;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin: -2.5px 20px -2.5px 0;
    }

    .pad-info {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100%;
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }

    .title {
      color: #000;
      font-size: 24px;
      line-height: 1.2em;
      text-decoration: none;
    }

    .info {
      color: #999;
      font-size: 16px;
      margin: 5px 0 0 auto;
    }
  }
}

.font {
  font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
}
</style>