<template>
<div class="main-page">
  <div class="side-bar-desk">
    <img src="./assets/logo.png" class="logo">
    <div class="nav-items">
      <ul class="ul-items">
        <li :class="['nav-li', selectedHash === 'gc' ? 'selected' : '']">
          <a class="font-body" href="#gc">GC贈物網</a>
        </li>
        <li :class="['nav-li', selectedHash === 'post' ? 'selected' : '']">
          <a class="font-body" href="#post">使用心得</a>
        </li>
        <li :class="['nav-li', selectedHash === 'download' ? 'selected' : '']">
          <a class="font-body" href="#download">立即下載</a>
        </li>
        <li :class="['nav-li', selectedHash === 'contact' ? 'selected' : '']">
          <a class="font-body" href="#contact">聯繫我們</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="nav-bar-mobile">
    <img src="./assets/logo.png" class="logo">
  </div>
  <div class="narbar-container">
    <div :class="['navbar-drawer-bar', openDrawer ? 'drawer-open' : '']">
      <div class="drawer-container">
        <a class="navbar" @click="openDrawer = !openDrawer">
          <div class="navbar-icons">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
    <transition name="fade">
      <div v-show="openDrawer" class="navbar-drawer-mask" @click="openDrawer = false"/>
    </transition>
    <div :class="['navbar-drawer', openDrawer ? 'drawer-open' : '']">
      <ul class="nav-drawer-list">
        <li :class="['nav-li', selectedHash === 'gc' ? 'selected' : '']" @click="openDrawer = false">
          <a class="font-body" href="#gc">GC贈物網</a>
        </li>
        <li :class="['nav-li', selectedHash === 'post' ? 'selected' : '']" @click="openDrawer = false">
          <a class="font-body" href="#post">使用心得</a>
        </li>
        <li :class="['nav-li', selectedHash === 'download' ? 'selected' : '']" @click="openDrawer = false">
          <a class="font-body" href="#download">立即下載</a>
        </li>
        <li :class="['nav-li', selectedHash === 'contact' ? 'selected' : '']" @click="openDrawer = false">
          <a class="font-body" href="#contact">聯繫我們</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="page-wrapper">
    <ul v-show="showHomePage" class="slides">
      <li class="slide" id="slide-gc">
        <a class="section-anchor" name="gc" id="gc"></a>
        <div class="section section-1 bg-blue">
          <div class="container">
            <img class="card" src="./assets/slide-1.jpeg">
            <a href="https://www.give-circle.com/aboutGC" target="_blank" class="button">
              專注打造物資「無償共享」生態圈，運用科技的力量，讓社福團體募集物資更輕鬆、更有效率！
            </a>
          </div>
        </div>
      </li>
      <li class="slide" id="slide-post">
        <a class="section-anchor" name="post" id="post"></a>
        <div class="section section-2 bg-gray">
          <div class="container pad-blogs">
            <div class="pad-blog-posts">
              <div v-for="(post, index) in posts.slice(0, 4)" :key="index" class="item-post">
                <a :href="post.link" class="link">
                  <div class="pad-post" :style="`background-image: url(${post.image})`">
                  </div>
                  <div class="pad-content">
                    <div class="title">{{ post.title }}</div>
                     <div class="time">{{ post.time }} · {{ post.tag }}</div>
                  </div>
                </a>
                <!-- <img :src="post.image" /> -->
              </div>
            </div>
            <a class="button btn-more" @click="showAllBlogs = true" target="_blank">更多文章</a>
          </div>
        </div>
      </li>
      <li class="slide" id="slide-download">
        <a class="section-anchor" name="download" id="download"></a>
        <div class="section section-3 bg-blue">
          <div class="container pad-download">
            <div class="pad-phone">
              <img src="./assets/app-phone.png">
            </div>
            <div class="pad-intro">
              <h2>GC贈物網</h2>
              <h4>專注打造物資「無償共享」生態圈，運用科技的力量，讓社福團體募集物資更輕鬆、更有效率！</h4>
              <a class="button btn-download" href="https://www.give-circle.com/app" target="_blank">下載APP</a>
            </div>
          </div>
        </div>
      </li>
      <li class="slide" id="slide-reward">
        <a class="section-anchor" name="reward" id="reward"></a>
        <div class="section section-4 bg-gray">
          <div class="container pad-rewards">
            <h2>
              我們始終相信「共享，能帶來共好」
            </h2>
            <h4>
              🏆近年獲得的榮譽和肯定🏆
            </h4>
            <ul class="rewards">
              <li>
                2021-TSAA台灣永續行動獎《台灣永續青年獎》
              </li>
              <li>
                2021-BCCT台北英僑商務協會《Corporate Social Responsibility企業社會責任獎》優選
              </li>
              <li>
                2020-APSIPA亞太地區社會創新合作獎《Biosphere Sustainability 環境永續獎》
              </li>
              <li>
                2020-Generation T新銳先鋒《社會影響力獎》
              </li>
              <li>
                2019-WITSA全球資通訊科技應用傑出貢獻獎《永續發展傑出獎》
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="slide" id="slide-contact">
        <a class="section-anchor" name="contact" id="contact"></a>
        <div class="section section-5 bg-white">
          <div class="container pad-contact">
            <div class="pad-info">
              <h2>聯繫我們</h2>
              <a class="email" target="_self" href="mailto:shin@give-circle.com">
                <span>shin@give-circle.com</span>
              </a>
            </div>
            <div class="pad-apps">
              <a class="link" href="https://apps.apple.com/tw/app/%E8%B4%88%E7%89%A9%E7%B6%B2/id1433535336" target="_blank">
                <img src="./assets/app-store.png" />
              </a>
              <a class="link" href="https://play.google.com/store/apps/details?id=com.give543.application" target="_blank">
                <img src="./assets/google-play.png" />
              </a>
              <a class="link" href="https://www.give-circle.com" target="_blank">
                <img src="./assets/web.png" />
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <blog1 v-if="currentHash === 'blog_1'" :post="posts[0]" :next="posts[1]"/>
    <blog2 v-if="currentHash === 'blog_2'" :post="posts[1]" :prev="posts[0]" :next="posts[2]"/>
    <blog3 v-if="currentHash === 'blog_3'" :post="posts[2]" :prev="posts[1]" :next="posts[3]"/>
    <blog4 v-if="currentHash === 'blog_4'" :post="posts[3]" :prev="posts[2]" :next="posts[4]"/>
    <blog5 v-if="currentHash === 'blog_5'" :post="posts[4]" :prev="posts[3]"/>
  </div>
  <transition name="modal">
    <all-blog v-if="showAllBlogs" :posts="posts" @close="showAllBlogs = false" />
  </transition>
</div>
</template>

<script>
import AllBlog from './components/all-blog'
import blog1 from './components/blog-1'
import blog2 from './components/blog-2'
import blog3 from './components/blog-3'
import blog4 from './components/blog-4'
import blog5 from './components/blog-5'

import post1 from './assets/post-1.jpg'
import post2 from './assets/post-2.jpg'
import post3 from './assets/post-3.jpg'
import post4 from './assets/post-4.jpg'
import post5 from './assets/post-5.jpg'

export default {
  name: 'App',
  components: {
    AllBlog,
    blog1,
    blog2,
    blog3,
    blog4,
    blog5
  },
  data () {
    return {
      openDrawer: false,
      showAllBlogs: false,
      currentHash: '',
      selectedHash: 'gc',
      posts: [
        {
          title: '募集物資常見問題',
          time: '2022年4月25日',
          tag: '功能介紹',
          image: post1,
          link: '#blog_1'
        },
        {
          title: '社福使用GC贈物網心得',
          time: '2022年4月25日',
          tag: 'GC影片',
          image: post2,
          link: '#blog_2'
        },
        {
          title: '如何申請GC贈物網公益帳號',
          time: '2022年4月25日',
          tag: '功能介紹',
          image: post3,
          link: '#blog_3'
        },
        {
          title: '走訪各個社福單位',
          time: '2022年4月25日',
          tag: 'GC影片',
          image: post4,
          link: '#blog_4'
        },
        {
          title: '引領永續低碳新生活',
          time: '2022年4月21日',
          tag: 'GC影片',
          image: post5,
          link: '#blog_5'
        }
      ]
    }
  },
  computed: {
    showHomePage () {
      return !this.currentHash || !this.currentHash.startsWith('blog_')
    }
  },
  mounted () {
    this.currentHash = window.location.hash ? window.location.hash.split("#")[1] : ''
    this.selectedHash = this.currentHash || 'gc'
    window.addEventListener("hashchange", (e) => {
      this.currentHash = e.newURL.split("#")[1]
      this.selectedHash = this.currentHash
      if (this.currentHash.startsWith('blog_')) {
        window.document.scrollingElement.scrollTo(0, 0)
      } else {
        let elem = document.getElementById('slide-' + this.currentHash)
        if (elem) {
          this.$nextTick(() => {
            window.document.scrollingElement.scrollTo(0, elem.offsetTop)
          })
        }
      }
    })
    window.addEventListener('scroll', () => {
      if (this.showHomePage) {
        const tags = ['gc', 'post', 'download', 'contact']
        if (document.scrollingElement.scrollTop + document.scrollingElement.clientHeight >= document.scrollingElement.scrollHeight - 500) {
          this.selectedHash = 'contact'
          return
        }
        for (let i = tags.length - 1; i >= 0; --i) {
          const elem = document.getElementById('slide-' + tags[i])
          if (elem && document.scrollingElement.scrollTop > elem.offsetTop - 100) {
            this.selectedHash = tags[i]
            break
          }
        }
      }
    })
  },
  watch: {
    openDrawer () {
      if (this.openDrawer) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
    showAllBlogs () {
      if (this.showAllBlogs) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  }
}
</script>

<style>
#app {
  font-family: -apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Arial,"PingFang SC","Microsoft Yahei","Hiragino Sans GB","Heiti SC","WenQuanYi Micro Hei",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
}

html {
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

.font {
  font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
}
</style>
<style lang="less" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.modal-enter-active, .modal-leave-active {
  transition: all 0.3s ease 0s;
}
.modal-enter, .modal-leave-to {
  transform: translate3d(0,15px,0);
  opacity: 0;
}

.main-page {
  position: relative;
}

.side-bar-desk {
  width: 30%;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: flex-start;
  position: fixed;
  padding: 60px;
  z-index: 210;
  height: 100%;
  box-shadow: rgb(0 0 0 / 12%) 1px 0px;
  box-sizing: border-box;
  text-align: left;
  flex-direction: column;

  @media screen and (max-width: 727px) {
    display: none;
  }

  @media screen and (max-width: 899px) {
    width: 34%;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    width: 30%;
  }

  @media screen and (min-width: 1200px) {
    width: 26%;
  }

  .logo {
    max-width: 100%;
    max-height: 200px;
  }

  .nav-items {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.8);
  }

  ul.ul-items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav-li {
    cursor: pointer;
    display: block;
    margin: 10px 0px;

    &.selected {
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    }
  }

  .font-body {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;

    &:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    }
  }
}

.nav-bar-mobile {
  display: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  text-align: center;
  padding: 5px;
  min-height: 50px;

  @media screen and (max-width: 727px) {
    display: flex;
  }

  .logo {
    max-height: 50px;
    max-width: calc(100vw - 130px);
  }
}

.narbar-container {
  .navbar-drawer-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1010;
    background-color: transparent;
    pointer-events: none;
    box-sizing: border-box;
    transition: all .4s cubic-bezier(.7,0,.3,1);
    display: none;

    @media screen and (max-width: 727px) {
      display: block;
    }

    .drawer-container {
      padding: 10px;
      height: 40px;
      position: relative;
      text-align: center;

      .navbar {
        background: rgb(255, 255, 255);
        border-radius: 100px;

        display: block;
        position: relative;
        z-index: 1020;
        pointer-events: all;
        color: #fff;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        outline-style: none;
      }

      .navbar-icons {
        width: 24px;
        height: 17px;
        position: relative;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        display: inline-block;
        cursor: pointer;

        span {
          background-color: rgb(0, 0, 0);
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          border-radius: 2px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          transition: .25s ease-in-out;

          &:first-child {
            top: 0;
            -webkit-transform-origin: left center;
            transform-origin: left center;
          }

          &:nth-child(2) {
            top: 7px;
            -webkit-transform-origin: left center;
            transform-origin: left center;
          }

          &:nth-child(3) {
            top: 14px;
            -webkit-transform-origin: left center;
            transform-origin: left center;
          }
        }
      }
    }

    &.drawer-open {
      .drawer-container {
        .navbar-icons {
          span {
            background-color: rgb(239, 73, 77);
            &:first-child {
              transform: rotate(45deg);
              top: 0;
              left: 3px;
            }

            &:nth-child(2) {
              width: 0;
              opacity: 0;
            }

            &:nth-child(3) {
              transform: rotate(-45deg);
              top: 17px;
              left: 3px;
            }
          }
        }
      }
    }
  }

  .navbar-drawer {
    width: 100%;
    background: #fff;
    position: fixed;
    overflow: auto;
    top: 0;
    z-index: 1002;
    transform: translateY(-100%);
    transition: all .4s ease-in-out;

    &.drawer-open {
      display: block;
      transform: translateY(0);
    }
  }

  .nav-drawer-list {
    list-style: none;
    position: relative;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    padding-top: 44px;
    border-right: 1px solid #fdedee;

    li {
      display: block;
      position: relative;
      z-index: 1015;
      padding: 0 24px;

      &.selected {
        a {
          background: #fdedee;
          box-shadow: 5px 0 0 0 #facbcc inset;
        }
      }
      a {
        text-decoration: none;
        display: block;
        color: #666;
        padding: 15px 30px;
        font-size: 16px;
        font-weight: 300;
        border-bottom: 1px solid #facbcc;
        font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
      }
    }
  }

  .navbar-drawer-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    background-color: rgba(0,0,0,.4);
  }
}

.page-wrapper {
  width: 70%;
  float: right;

  @media screen and (max-width: 899px) {
    width: 66%;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    width: 70%;
  }

  @media screen and (min-width: 1200px) {
    width: 74%;
  }

  @media screen and (max-width: 727px) {
    width: 100%;
  }
}

.slides {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.slides li.slide {
  position: relative;
  outline: none;
}

.section {
  padding: 75px 0;
  text-align: center;
}

.bg-blue {
  background-color: rgb(41, 77, 143);
}

.bg-gray {
  background-color: rgb(232, 234, 236);
}

.bg-white {
  background-color: white;
}

.container {
  max-width: 94%;
  margin: 0 auto;
  .card {
    max-width: 100%;
  }
}

.button {
  font-size: 16px;
  padding: 12px 18px;
  color: white;
  border: 1px solid white;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
}

.pad-download {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .pad-phone {
    width: 50%;
  }

  .pad-intro {
    color: white;
    text-align: left;
    width: 50%;
  }

  @media only screen and (max-width: 727px) {
    flex-direction: column;

    .pad-phone {
      width: 100%;
    }

    .pad-intro {
      width: 100%;
    }
  }
}

.btn-download {
  color: rgba(0, 0, 0, 0.8);
  background: rgb(244, 229, 64);
  font-size: 20px;
  padding: 16px 28px;
  border: 0;
  margin-top: 20px;
}

.pad-rewards {
  text-align: left;

  ul.rewards {
    list-style: disc;
    padding-left: 2em;
    margin: 1em 0;

    li {
      margin: 5px 0;
    }
  }
}

.pad-contact {
  font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;

  .pad-info {
    margin-bottom: 40px;
  }

  .email {
    color: #170202;
    text-decoration: none;
  }

  .pad-apps {
    vertical-align: middle;
    .link {
      margin-left: 1.04166666%;
      margin-right: 1.04166666%;
      img {
        vertical-align: middle;
        max-width: 110px;
      }
    }
  }
}

.pad-blogs {
  .btn-more {
    background-color: rgb(239, 73, 77);
    margin-top: 28px;
  }
  margin-bottom: 20px;
}

.pad-blog-posts {
  width: 100%;
  .item-post {
    position: relative;
    width: 23%;
    display: inline-block;
    margin: 0 1%;

    @media (min-width: 728px) and (max-width: 1024px) {
      width: 48%;
    }

    @media (max-width: 727px) {
      width: 100%;
      padding: 15px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .link {
      text-decoration: none;
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
    }

    .pad-post {
      padding-bottom: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      margin-bottom: 15px;

      @media screen and (min-width: 728px) {
         &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }
    }

    .pad-content {
      text-align: left;
      font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
      color: #222;
      text-align: left;


      @media screen and (min-width: 728px) {
        bottom: 25px;
        display: block;
        margin: 0px 15px;
        position: absolute;
        z-index: 1;
        color: white;
      }
    }

    .title {
      font-size: 26px;
      margin-bottom: 5px;

      @media screen and (min-width: 728px) {
        font-size: 18px;
      }
    }
    .time {
      font-size: 12px;
    }
  }
}

@media only screen and (min-width: 728px) and (max-width: 1024px) {

}
</style>