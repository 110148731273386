<template>
  <div class="blog-wrapper">
    <div class="header font">
      <div class="pad-cover" :style="`background-image: url(${post.image})`">
        <div class="header-content">
          <div class="title container">
            <h1>{{ post.title }}</h1>
          </div>
        </div>
      </div>
      <div class="header-bottom">
        <div class="container">
          <div class="blog-info">
            {{ post.time }}·{{ post.tag }}
          </div>
        </div>
      </div>
    </div>
    <div class="blog-body">
      <div class="container">
        <div class="blog-content font">
          <div class="block-item">
            <p style="text-align: start;"><span class="s-text-color-default">所有帳號最初加入贈物網時，都會預設為「一般會員」，若您代表的是</span><span class="s-text-color-default"><strong>某個組織團體</strong></span><span class="s-text-color-default">（如公私立社福機構、各級學校、政府機關或企業行號等），可以透過「</span><span class="s-text-color-default"><strong>變更會員身份</strong></span><span class="s-text-color-default">」的機制來調整帳號的性質，由站方管理員審核相關身份證明，幫助您在平台上的活動更具公信力，也讓您能夠以組織團體的身份，在平台上累積物資交流紀錄，看見社會影響力！</span></p>
          </div>
          <div class="block-item">
            <strong>電腦版，進入申請頁面路徑：</strong>
          </div>
          <div class="block-item">
            <p style="text-align: start;"><span class="s-text-color-default"><strong>01 登入/註冊GC贈物網 </strong></span><span class="s-text-color-default">登入/註冊GC贈物網&gt; </span></p>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-3-1.jpeg"/>
            <br>
          </div>
          <div class="block-item">
            <p class="s-text-color-default s-text-color-default s-text-color-default s-text-color-default s-text-color-default s-text-color-default"><span class="s-text-color-default"><strong>02 進入個人管理平台 </strong></span><span class="s-text-color-default">登入/註冊GC贈物網&gt; 進入個人管理平台&gt;</span></p>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-3-2.jpeg"/>
            <br>
          </div>
          <div class="block-item">
            <p class="s-text-color-default s-text-color-default s-text-color-default s-text-color-default s-text-color-default s-text-color-default"><span class="s-text-color-default"><strong>03 個人資料維護</strong></span><span class="s-text-color-default">&gt; </span><span class="s-text-color-default"><strong>變更會員身份</strong></span></p>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-3-3.jpeg"/>
            <br>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-3-4.jpeg"/>
            <br>
          </div>
          <div class="block-item">
            <p class="s-text-color-default s-text-color-default s-text-color-default s-text-color-default s-text-color-default s-text-color-default"><span class="s-text-color-default">進入變更會員身份頁面後，選擇您欲申請變更的身份別，點選「下一步」，按照指示填寫完整資料，並上傳相關證明資料後即送出申請。後續請稍待</span><span class="s-text-color-default"><strong>2～7個工作天</strong></span><span class="s-text-color-default">，資料審核完畢後站方將為您開通身份變更，審核通過後，亦會收到站內信通知。</span></p>
          </div>
          <div class="block-item">
            <span class="s-text-color-default">☆建議使用電腦來填寫申請資料會更方便唷！</span>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item">
            <strong>手機版操作方式</strong>
          </div>
          <div class="block-item">
            <span class="s-text-color-default">1.登入後，請至「我的」點選右上角的「齒輪 」，再點「會員變更」</span>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-3-5.jpeg"/>
          </div>
          <div class="block-item">
            <span class="s-text-color-default">2.選擇欲變更的身份類別，按指示填寫完資料，並上傳相關證明資料後按「確認送出」，資料審核完畢後站方將為您開通身份變更。</span>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-3-6.jpeg"/>
          </div>
          <div class="block-item">
            <p style="text-align: start;"><span style="color: #170202;">以上是這次關於「變更會員身份」的功能介紹，還沒順利更改會員身份的朋友們，請針對您或者您所屬的組織團體的需求，按照上述步驟提出申請吧！：）</span></p>
          </div>
          <div class="block-item">
            <p style="text-align: start;"><span style="color: #170202;">（如有說明未盡之處或其他問題，請來信：</span><span class="s-text-color-default"><a href="mailto:shin@give-circle.com" target="_blank">shin@give-circle.com</a></span><span style="color: #170202;">）</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-footer">
      <div class="container">
        <div class="footer-nav">
          <div class="btn-previous footer-btn">
            <div>上一篇</div>
            <a v-if="prev" class="title" :href="prev.link">{{ prev.title }}</a>
          </div>
          <div class="btn-next footer-btn">
            <div>下一篇</div>
            <a v-if="next" class="title" :href="next.link">{{ next.title }}</a>
          </div>
        </div>
        <div class="footer-toolbar">
          <a target="_self" href="/" class="back-link">
            <span class="left-open-big"></span>
            &nbsp;返回網站
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    prev: {
      type: Object,
      default: null
    },
    next: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="less">
.font {
  font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
}

.center {
  text-align: center;
}

.blog-wrapper {
  .container {
    position: relative;
    max-width: 816px;
    margin: 0 auto;
    padding: 0;
    padding: 0 15px;

    @media screen and (min-width: 728px) and (max-width: 879px) {
      max-width: 640px;
    }
    @media screen and (min-width: 590px) and (max-width: 727px) {
      max-width: 556px;
    }
    @media screen and (min-width: 490px) and (max-width: 589px) {
      max-width: 456px;
    }
    @media screen and (min-width: 370px) and (max-width: 429px) {
      max-width: 336px;
    }
    @media screen and (max-width: 349px) {
      max-width: 296px;
    }
  }

  .header {
    .pad-cover {
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      background-position: 50% 50%;
      color: #fff;
      height: 340px;
      display: table;
      width: 100%;

      // @media screen and (max-width: 727px) {
      //   height: 220px;
      // }

      .header-content {
        padding: 40px 0 60px;
        display: table-cell;
        vertical-align: bottom;
      }

      .title {
        font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
        text-shadow: 0 1px 12px rgb(0 0 0 / 35%);
      }
    }

    .header-bottom {
      .blog-info {
        padding: 12px 0;
        border-bottom: 1px solid #ddd;
        color: #a9aeb2;
        font-size: 14px;
      }
    }
  }
}

.blog-body {
  padding: 30px 0;
  .blog-content {
    color: #444;
    line-height: 1.7;
    word-wrap: break-word;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;

    .video-wrapper {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;
    }

    p {
      margin: 0;
      padding: 0;
      border: 0;
    }

    .block-item {
      margin-bottom: 30px;
    }

    .img-item {
      max-width: 100%;
    }

    .separator {
      height: 2px;
      background: #ddd;
      width: 240px;
      margin: 0 auto;
      border-radius: 2px;
    }
  }
}
.blog-footer {
  padding-top: 30px;
  padding-bottom: 200px;
  background: #fff;
  color: #999;

  .footer-nav {
    padding: 22px 0;
    font-size: 14px;
    border-top: 1px solid #ddd;
    color: #999;
  }

  .btn-previous {
    padding-right: 10px;
    box-sizing: border-box;
    color: #a9aeb2;
  }
  .footer-btn {
    display: inline-block;
    zoom: 1;
    width: 50%;
    vertical-align: top;
  }

  .btn-next {
    padding-left: 10px;
    box-sizing: border-box;
    color: #a9aeb2;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    color: #000;
    margin-top: 5px;
    line-height: 1.25;
    font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
    cursor: pointer;
    text-decoration: none;
  }

  .footer-toolbar {
    font-size: 14px;
    padding-top: 15px;
    border-top: 1px solid #ddd;

    .back-link {
      display: inline-block;
      margin: 0 30px 10px 0;
      line-height: 20px;
      color: #a9aeb2;
      text-decoration: none;

      .left-open-big {
        &::before {
          content: "<";
          font-family: entypo;
          font-style: normal;
          font-weight: 400;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
        }
      }
    }
  }
}
</style>