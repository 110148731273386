<template>
  <div class="blog-wrapper">
    <div class="header font">
      <div class="pad-cover" :style="`background-image: url(${post.image})`">
        <div class="header-content">
          <div class="title container">
            <h1>{{ post.title }}</h1>
          </div>
        </div>
      </div>
      <div class="header-bottom">
        <div class="container">
          <div class="blog-info">
            {{ post.time }}·{{ post.tag }}
          </div>
        </div>
      </div>
    </div>
    <div class="blog-body">
      <div class="container">
        <div class="blog-content font">
          <div class="block-item center">
            <img class="img-item" src="../assets/post-1-1.png"/>
          </div>
          <div class="block-item">
            <span style="color: #c01114; font-size: 28px;">1.經費有限，無法買廣告等宣傳方式曝光單位的服務與需求</span>
          </div>
          <div class="block-item">
            <p style="font-size: 20px;">
              <span style="color: #121212;">→</span>
              成為GC贈物網NPO會員<u>免年費</u>、<u>免手續費</u>，<span style="color: #ef484b;">【免費】</span>協助社福團體曝光物資需求，完全不抽成。 GC贈物網有專責的公益部門，並設置公益長職位，全年走訪全台社福單位，並撰寫<a href="https://www.give-circle.com/stories?category_id=24&page=1" target="_blank">走訪紀錄</a>，將單位介紹給平台上9萬多位熱愛共享、環保、公益的會員。在GC贈物網，不論單位大小，只要用心服務、用心互動，就能獲得推薦並持續得到各類資源。
            </p>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-1-2.png"/>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 28px;"><span style="color: #c01114;">2.</span>&nbsp;<span style="color: #c01114;">宣傳管道不足，只有官網及粉絲團</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <p style="font-size: 20px;"><span style="color: #121212;">GC贈物網在</span><span style="color: #121212;"><u>首頁</u></span><span style="color: #121212;">開闢公益募集專區，社福單位發佈的需求皆放大版面優先展示，並設有公益團體限定的「</span><a href="https://www.give-circle.com/story/22102" target="_blank">說說</a><span style="color: #121212;">」版位（類似部落格），便於社福單位宣達事項、發佈服務動態、與潛在捐贈人互動，長期累積人氣與關注度。</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <p style="font-size: 20px;"><span style="color: #121212;">GC贈物網每年舉辦</span><a href="https://www.give-circle.com/stories?keyword=%E7%B5%A6%E7%A4%BE%E5%B7%A5%E6%84%9B%E7%9A%84%E9%BC%93%E5%8B%B5&page=1" target="_blank">「給社工愛的鼓勵」</a><span style="color: #121212;">以及</span><a href="https://www.give-circle.com/stories?keyword=%E7%A4%BE%E7%A6%8F%E4%BA%92%E5%8B%95%E7%8D%8E&page=1" target="_blank">「社福互動獎」</a><span style="color: #121212;">等社福界的公益活動，邀請企業贊助，並鼓勵民眾參與，透過創新與創意，幫社福爭取資源、提高能見度，長期賦能社福工作者！</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-1-3.png"/>
          </div>
          <div class="block-item">
            <p style="font-size: 28px;"><span style="color: #c01114;">3.</span>&nbsp;<span style="color: #c01114;">需求物資時常募不滿</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">→GC贈物網提供多元化的物資媒合方式，讓募集成功率更高。</span>&nbsp;</p>
            <br/>
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">l</span>&nbsp;<span style="color: #121212;">隨時刊登「需求」物品，由民眾捐贈</span>&nbsp;</p>
            <br/>
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">l</span>&nbsp;<span style="color: #121212;">主動出擊，</span><a href="https://youtu.be/ZxpJl8smzZM" target="_blank">排「禮物」</a>&nbsp;</p>
            <br/>
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">l</span>&nbsp;<a href="https://www.give-circle.com/story/21923" target="_blank">設定關鍵字</a><span style="color: #121212;">，第一時間收到特定物品上架通知</span>&nbsp;</p>
            <br/>
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">l</span><a href="https://www.give-circle.com/story/22312" target="_blank">公益直購</a>，民眾買捐，由店家直送社福單位據點</p>
            <br/>
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">l不定期企業公益專案，為社福增添實用物資(例如：</span><span class="s-text-color-default"><a href="https://www.give-circle.com/story/22377" target="_blank">中秋</a></span><span class="s-text-color-default"><a href="https://www.give-circle.com/story/22377" target="_blank">月餅專案</a></span><span style="color: #121212;">、</span><span class="s-text-color-default"><a href="https://www.give-circle.com/story/22399" target="_blank">臥式冰櫃</a></span><span class="s-text-color-default"><a href="https://www.give-circle.com/story/22399" target="_blank">專案</a></span><span class="s-text-color-default">)</span></p>
            <br/>
            <p class="MsoListParagraph" style="font-size: 20px;">l<a href="https://www.give-circle.com/stories?category_id=28&page=1" target="_blank"> 志工參訪</a>，有機會與企業現場互動並獲得額外資源 </p>
            <br/>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-1-4.png"/>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 28px;"><span style="color: #c01114;">4.</span><span style="color: #c01114;"> 時常收到不需要的物資，或已募滿仍持續收到該品項的包裹</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">→</span>&nbsp;<span style="color: #121212;">GC</span><span style="color: #121212;">贈物網主張</span><span style="color: #121212;"><strong>精準媒合</strong></span><span style="color: #121212;">，透過</span><span style="color: #121212;">機制</span><span style="color: #121212;">設計，</span><span style="color: #121212;">最大程度</span><span style="color: #121212;">捐贈者的</span><span style="color: #121212;"><u>避免愛心傾倒</u></span><span style="color: #121212;">及社福單位的物資困擾。捐物必須由單位確認收下方可寄出，另外，募集進度公開展示且即時更新，避免過量的物資湧入，避免過時的資訊四處流傳</span><span style="color: #121212;">。</span><span style="color: #121212;">在</span><span style="color: #121212;">GC</span><span style="color: #121212;">贈物網，忙碌的社福工作人員得以高效管理</span><span style="color: #121212;">募集數量和進度。</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-1-5.png"/>
          </div>
          <div class="block-item">
            <p style="font-size: 28px;"><span style="color: #c01114;">5.無法有效率的與捐贈者互動，建立長期關係</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">→</span><span style="color: #121212;">GC</span><span style="color: #121212;">贈物網提供社福與捐贈者公開互動的管道，</span><span style="color: #121212;">收到物資後</span><span style="color: #121212;">藉由</span><span style="color: #121212;">「</span><span style="color: #121212;"><strong>感謝</strong></span><span style="color: #121212;">」</span><span style="color: #121212;">機制，回饋圖文給捐贈者，建立用心服務的印象，大幅增加日後再度獲得捐贈的機率</span><span style="color: #121212;">。</span><span style="color: #121212;">捐贈進行中，也</span><span style="color: #121212;">可以使用</span><span style="color: #121212;">即時訊息與</span><span style="color: #121212;">捐贈者聯繫。</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
          <div class="block-item center">
            <img class="img-item" src="../assets/post-1-6.png"/>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 28px;"><span style="color: #c01114;">6.</span>&nbsp;<span style="color: #c01114;">收到廠商或民眾捐贈大量的即期物資，所以無法短時間使用完畢，只能放到壞掉，或是花更多時間找尋需要的人</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <p class="MsoListParagraph" style="font-size: 20px;"><span style="color: #121212;">→</span><span style="color: #121212;">GC</span><span style="color: #121212;">贈物網是物資雙向流通的平台</span><span style="color: #121212;">，</span><span style="color: #121212;">歡迎</span><span style="color: #121212;">社福使用</span><a href="https://youtu.be/LgXpHqGOzqc" target="_blank">「上傳禮物」功能</a><span style="color: #121212;">，</span>&nbsp;<span style="color: #121212;">將</span><span style="color: #121212;">多餘的物資</span><span style="color: #121212;">分享</span><span style="color: #121212;">給其他需要的</span><span style="color: #121212;">單位，形成社福之間彼此流通、以有餘補不足的互助社群，</span><span style="color: #121212;">目前GC</span><span style="color: #121212;">贈物網上有</span><span style="color: #121212;">公益團體及偏鄉學校超過580間。</span>&nbsp;</p>
          </div>
          <div class="block-item">
            <div class="separator"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-footer">
      <div class="container">
        <div class="footer-nav">
          <div class="btn-previous footer-btn">
            <div>上一篇</div>
            <a v-if="prev" class="title" :href="prev.link">{{ prev.title }}</a>
          </div>
          <div class="btn-next footer-btn">
            <div>下一篇</div>
            <a v-if="next" class="title" :href="next.link">{{ next.title }}</a>
          </div>
        </div>
        <div class="footer-toolbar">
          <a target="_self" href="/" class="back-link">
            <span class="left-open-big"></span>
            &nbsp;返回網站
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    prev: {
      type: Object,
      default: null
    },
    next: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="less">
.font {
  font-family: montserrat,'PingFang TC','Microsoft JhengHei','微軟正黑體',STXihei,sans-serif;
}

.center {
  text-align: center;
}

.blog-wrapper {
  .container {
    position: relative;
    max-width: 816px;
    margin: 0 auto;
    padding: 0;
    padding: 0 15px;

    @media screen and (min-width: 728px) and (max-width: 879px) {
      max-width: 640px;
    }
    @media screen and (min-width: 590px) and (max-width: 727px) {
      max-width: 556px;
    }
    @media screen and (min-width: 490px) and (max-width: 589px) {
      max-width: 456px;
    }
    @media screen and (min-width: 370px) and (max-width: 429px) {
      max-width: 336px;
    }
    @media screen and (max-width: 349px) {
      max-width: 296px;
    }
  }

  .header {
    .pad-cover {
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      background-position: 50% 50%;
      color: #fff;
      height: 340px;
      display: table;
      width: 100%;

      // @media screen and (max-width: 727px) {
      //   height: 220px;
      // }

      .header-content {
        padding: 40px 0 60px;
        display: table-cell;
        vertical-align: bottom;
      }

      .title {
        font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
        text-shadow: 0 1px 12px rgb(0 0 0 / 35%);
      }
    }

    .header-bottom {
      .blog-info {
        padding: 12px 0;
        border-bottom: 1px solid #ddd;
        color: #a9aeb2;
        font-size: 14px;
      }
    }
  }
}

.blog-body {
  padding: 30px 0;
  .blog-content {
    color: #444;
    line-height: 1.7;
    word-wrap: break-word;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;


    p {
      margin: 0;
      padding: 0;
      border: 0;
    }

    .block-item {
      margin-bottom: 30px;
    }

    .img-item {
      max-width: 100%;
    }

    .separator {
      height: 2px;
      background: #ddd;
      width: 240px;
      margin: 0 auto;
      border-radius: 2px;
    }
  }
}
.blog-footer {
  padding-top: 30px;
  padding-bottom: 200px;
  background: #fff;
  color: #999;

  .footer-nav {
    padding: 22px 0;
    font-size: 14px;
    border-top: 1px solid #ddd;
    color: #999;
  }

  .btn-previous {
    padding-right: 10px;
    box-sizing: border-box;
    color: #a9aeb2;
  }
  .footer-btn {
    display: inline-block;
    zoom: 1;
    width: 50%;
    vertical-align: top;
  }

  .btn-next {
    padding-left: 10px;
    box-sizing: border-box;
    color: #a9aeb2;
  }

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    color: #000;
    margin-top: 5px;
    line-height: 1.25;
    font-family: "istok web", helvetica, 'Lisong Pro', 'PMingLiU', 'PingFang TC','Microsoft JhengHei',"微軟正黑體",STXihei, sans-serif;
    cursor: pointer;
    text-decoration: none;
  }

  .footer-toolbar {
    font-size: 14px;
    padding-top: 15px;
    border-top: 1px solid #ddd;

    .back-link {
      display: inline-block;
      margin: 0 30px 10px 0;
      line-height: 20px;
      color: #a9aeb2;
      text-decoration: none;

      .left-open-big {
        &::before {
          content: "<";
          font-family: entypo;
          font-style: normal;
          font-weight: 400;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
        }
      }
    }
  }
}
</style>